export default {
  proxy_path: "https://bn7odtqena.execute-api.us-west-2.amazonaws.com/api",
  proxy_path_geojson_api: "https://prp25r6lgg.execute-api.us-west-2.amazonaws.com/api",
  google_api_key: "AIzaSyB9O0BHfLu42XEe_50uDzubnJzPHFI0m6s",
  main_company_term_id: "9593",
  crm_base_url: "https://crm.staging.evolution.forwoodsafety.com",
  forwood_id_url: "https://id.staging.evolution.forwoodsafety.com?redirect_uri=https://mapreport.staging.evolution.forwoodsafety.com",
  custom_tile_url: "https://mapoverlays.geoeditor.staging.evolution.forwoodsafety.com",
  Auth: {
    userPoolId: "us-west-2_2vNscmUHY",
    userPoolWebClientId: "5k7u30kr1b3h0cgv6dvbnj92qc",
    cookieStorage: {
      domain: ".staging.evolution.forwoodsafety.com",
      secure: true
    }
  }
};
